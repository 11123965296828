import { Comment } from '../../types';
import { timeAgo } from '../../utils';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';
import ButtonIcon from '../common/ButtonIcon';
import { Avatar } from 'primereact/avatar';
import FeedbackInput from '../common/FeedbackInput';
import RepliesContainer from './RepliesContainer';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import replyIcon from '../../assets/images/reply.png';
import { RootState } from '../../store';

const CommentsContainer = ({
  comments,
  setComments,
}: {
  comments: Comment[];
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}) => {
  const user = useSelector((state: RootState) => state.user.user);

  const [replyToCommentId, setReplyToCommentId] = useState<number | null>(null);

  const handleAddReply = async (commentId: number, content: string) => {
    if (content.trim()) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/comments/add-reply`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              commentId: commentId,
              content,
            }),
          }
        );

        if (!response.ok) {
          console.log('Error adding reply');
        }
        const data = await response.json();
        const newReply = {
          ...data,
          _count: {
            comments: 0,
            commentVotes: 0,
          },
          user: {
            id: user.id,
            email: user.email,
            full_name: user.full_name,
            username: user.username,
            is_active: user.is_active,
          },
          comments: [],
        };

        setComments(
          comments.map((comment) => {
            if (comment.id === commentId) {
              return {
                ...comment,
                comments: [newReply, ...comment.comments],
              };
            }
            return comment;
          })
        );
        // console.log(data);
      } catch (error) {
        console.error(error);
      }
      // setReplyToCommentId(null);
    }
  };

  const updateUpvotes = (commentId: number, replyId: number | null) => {
    if (replyId === null)
      setComments(
        comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              _count: {
                ...comment._count,
                commentVotes: comment._count.commentVotes + 1,
              },
            };
          }
          return comment;
        })
      );
    else
      setComments(
        comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              comments: comment.comments.map((reply) => {
                if (reply.id === replyId) {
                  return {
                    ...reply,
                    _count: {
                      ...reply._count,
                      commentVotes: reply._count.commentVotes + 1,
                    },
                  };
                }
                return reply;
              }),
            };
          }
          return comment;
        })
      );
  };
  const toggleVote = async (commentId: number, replyId: number | null) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/toggle-upvote`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        updateUpvotes(commentId, replyId);
      } else {
        console.log('Error toggling vote');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateComment = (commentId: number, updatedComment: Comment[]) => {
    setComments((prevComments) => {
      const index = prevComments.findIndex((c) => c.id === commentId);
      if (index === -1) return prevComments;

      const newComments = [...prevComments];

      newComments[index] = {
        ...newComments[index],
        comments: updatedComment,
      };

      return newComments;
    });
  };
  return (
    <>
      {comments.map((comment, index) => (
        <div key={index} className="mt-4">
          <div className="flex w-full items-start">
            <Avatar
              image={comment.image_path || ''}
              size="large"
              shape="circle"
              className="mr-4"
            />
            <div className="flex-grow">
              <div className="flex w-full justify-between">
                <div>
                  <p className="font-bold">{comment.user.full_name}</p>
                  <p className="text-gray-700">
                    Posted {timeAgo(new Date(comment.created_at))}
                  </p>
                  <p
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{ __html: comment.content }}
                  />

                  <button
                    className="mt-2 flex flex-row items-end justify-start gap-1 font-bold"
                    onClick={() => setReplyToCommentId(comment.id)}
                  >
                    <img src={replyIcon} alt="Reply" className="w-[20px]" />
                    <span>Reply</span>
                  </button>
                </div>
                <div className="flex items-start justify-center gap-3">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <ButtonIcon
                      icon={
                        comment.upvotedByMe
                          ? 'pi pi-sort-up-fill'
                          : 'pi pi-sort-up'
                      }
                      className="rounded-full border border-primary-text px-2 py-1"
                      label={`${comment._count.commentVotes}`}
                      onClick={() => toggleVote(comment.id, null)}
                    />
                  </div>
                  <div>
                    {comment.medal_type === 'GOLD' && (
                      <img
                        src={goldMedal}
                        alt="Gold Medal"
                        className="h-8 w-8"
                      />
                    )}
                    {comment.medal_type === 'SILVER' && (
                      <img
                        src={silverMedal}
                        alt="Silver Medal"
                        className="h-8 w-8"
                      />
                    )}
                    {comment.medal_type === 'BRONZE' && (
                      <img
                        src={bronzeMedal}
                        alt="Bronze Medal"
                        className="h-8 w-8"
                      />
                    )}
                  </div>
                </div>
              </div>
              {comment.comments.length > 0 && (
                <RepliesContainer
                  replies={comment.comments}
                  toggleVote={toggleVote}
                  updateComment={updateComment}
                  commentId={comment.id}
                />
              )}
            </div>
          </div>
          <hr className="border-1 my-2 text-gray-600" />

          {replyToCommentId === comment.id && (
            <div className="ml-8 mt-2">
              <div className="flex items-start">
                <Avatar
                  image={comment.image_path || ''}
                  size="large"
                  shape="circle"
                  className="mr-1 mt-4"
                />
                <FeedbackInput
                  onSubmit={(content) => handleAddReply(comment.id, content)}
                  label="Reply"
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default CommentsContainer;
