import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { DeleteAccountProvider } from './hooks/DeleteAccountContext';
import { ExpandHoverProvider } from './hooks/ExpandHoverContext';
import { CompetitionDetails } from './types';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import Signin from './pages/Signin';
import Home from './pages/Home';
import Settings from './pages/Settings';
import CreateCompetition from './pages/CreateCompetition';
import Dashboard from './pages/Dashboard';
import NewCompetitions from './components/dashboard/NewCompetitions';
import CompetitionDetailsComp from './atoms/dashboard/CompetitionDetails';
import ApprovedCompetitions from './components/dashboard/ApprovedCompetitions';
import DeclinedCompetitions from './components/dashboard/DeclinedCompetitions';
import UnderReview from './components/dashboard/UnderReview';
import UsersInformation from './components/dashboard/UsersInformation';
import MainLayout from './components/MainLayout';
import CompetitionPage from './pages/CompetitionPage';
import Overview from './components/competition/Overview';
import Submissions from './components/competition/Submissions';
import Discussions from './components/competition/Discussions';
import Leaderboard from './components/competition/LeaderBoard';
// import Rules from './atoms/dashboard/Rules';

import DashboardOverview from './atoms/dashboard/DashboardOverview';
import DashboardResources from './atoms/dashboard/DashboardResources';
import DashboardRules from './atoms/dashboard/DashboardRules';
import DashboardFeedback from './atoms/dashboard/DashboardFeedback';

import AccountSettings from './components/settings/AccountSettings';
import NotificationsSettings from './components/settings/NotificationsSettings';
import Competitions from './pages/Competitions';
import DiscussionDetails from './atoms/competition/DiscussionDetails';
import CreateSubmission from './pages/CreateSubmission';
import EditSubmission from './pages/EditSubmission';
import SubmissionPage from './pages/SubmissionPage';
import HostDashboard from './pages/HostDashboard';
import HNewCompetitions from './components/hostdashboard/HNewCompetitions';
import HUnderReview from './components/hostdashboard/HUnderReview';
import HRunningCompetitions from './components/hostdashboard/HRunningCompetitions';
import HDeclinedCompetitions from './components/hostdashboard/HDeclinedCompetitions';
import HEndedCometitions from './components/hostdashboard/HEndedCometitions';
import RunningCompetitionDetails from './components/dashboard/RunningCompetitionDetails';
import Guidelines from './pages/Guidelines';
import Progression from './pages/Progression';
import Rules from './components/competition/Rules';
import HRatedCompetitions from './components/hostdashboard/HRatedCompetitions';
import DiscussionsPage from './pages/DiscussionsPage';

function App() {
  const [competitions, setCompetitions] = useState<CompetitionDetails[]>([]);

  useEffect(() => {
    const savedCompetitions = JSON.parse(
      localStorage.getItem('competitions') as string
    );
    if (savedCompetitions && savedCompetitions.length > 0) {
      setCompetitions(savedCompetitions);
    } else {
      // setCompetitions(initialCompetitions);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('competitions', JSON.stringify(competitions));
  }, [competitions]);

  return (
    <ExpandHoverProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/settings"
              element={
                <DeleteAccountProvider>
                  <Settings />
                </DeleteAccountProvider>
              }
            >
              <Route path="account" element={<AccountSettings />} />
              <Route path="notifications" element={<NotificationsSettings />} />
              <Route path="" element={<AccountSettings />} />
            </Route>
            <Route path="/discussions" element={<DiscussionsPage />} />
            <Route path="/create-competition" element={<CreateCompetition />} />
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path=":state/:id" element={<CompetitionDetailsComp />} />

              <Route path="new" element={<NewCompetitions />} />
              <Route path="approved" element={<ApprovedCompetitions />} />
              <Route path="declined" element={<DeclinedCompetitions />} />
              <Route path="under-review" element={<UnderReview />} />
              <Route path="users-information" element={<UsersInformation />} />
              <Route path="" element={<NewCompetitions />} />
            </Route>
            <Route path="/host-dashboard" element={<HostDashboard />}>
              <Route
                path="running/:id"
                element={<RunningCompetitionDetails />}
              />
              <Route path="ended/:id" element={<RunningCompetitionDetails />} />

              <Route path=":state/:id" element={<CompetitionDetailsComp />} />
              <Route path="new" element={<HNewCompetitions />} />
              <Route path="under-review" element={<HUnderReview />} />
              <Route path="running" element={<HRunningCompetitions />} />
              <Route path="declined" element={<HDeclinedCompetitions />} />
              <Route path="ended" element={<HEndedCometitions />} />
              <Route path="rated" element={<HRatedCompetitions />} />
              <Route path="" element={<HNewCompetitions />} />
            </Route>

            <Route path="/competitions">
              <Route
                path=":id/submit"
                element={
                  <MainLayout>
                    <CreateSubmission />
                  </MainLayout>
                }
              />
              <Route
                path=":id/submit/:submissionId"
                element={
                  <MainLayout>
                    <EditSubmission />
                  </MainLayout>
                }
              />
              <Route
                path=":id/submissions/:submissionId"
                element={<SubmissionPage />}
              />
              <Route
                path=":id"
                element={
                  <MainLayout>
                    <CompetitionPage />
                  </MainLayout>
                }
              >
                <Route path="overview" element={<Overview />} />
                <Route path="submissions" element={<Submissions />} />
                <Route path="discussions">
                  <Route path=":discussionId" element={<DiscussionDetails />} />
                  <Route path="" element={<Discussions />} />
                </Route>
                <Route path="leaderboard" element={<Leaderboard />} />
                <Route path="rules" element={<Rules />} />
                <Route path="" element={<Overview />} />
              </Route>
              <Route path="" element={<Competitions />}></Route>
            </Route>
            <Route path="/community-guidelines" element={<Guidelines />} />
            <Route path="/progression" element={<Progression />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ExpandHoverProvider>
  );
}

export default App;
