import React, { useEffect, useState } from 'react';
import { Topic } from '../types';
import MainLayout from '../components/MainLayout';
import DiscussionItem from '../atoms/competition/DiscussionItem';
import SearchBar from '../components/header/SearchBar';

const DiscussionsPage = () => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const fetchTopics = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/topics`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTopics(data);
        console.log(data);
      } else {
        console.log('Error fetching topics');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);
  return (
    <MainLayout>
      <div className="my-24 lg:mx-8 lg:my-10">
        <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
          <h1 className="text-left text-4xl font-bold">Discussions</h1>
          <p className="lg:text-md text-left text-xs text-secondary-text md:w-96">
            Discuss the Kaggle platform & machine learning topics – this
            includes sharing feedback, asking questions, and more.
          </p>
        </header>
        <hr className="border-1 my-2 text-gray-600" />
        <div className='flex flex-col gap-3'>
          <h2 className="text-start text-xl font-semibold">
            Discussions from acorss bidayya
          </h2>
          <SearchBar placeholder="Search Discussions" />
          <div className="flex flex-col gap-2 ">
            {topics?.map((topic, index) => (
              <DiscussionItem key={index} topic={topic} />
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DiscussionsPage;
