import React from 'react';
import MainLayout from '../components/MainLayout';

const Guidelines = () => {
  return (
    <MainLayout>
      <div className="my-24 lg:mx-8 lg:my-10">
        <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
          <h1 className="text-left text-4xl font-bold">Community Guidelines</h1>
          <p className="lg:text-md text-left text-xs text-secondary-text md:w-96">
            Ensuring Bidayya is a place where anyone in the world feels welcome
            to participate
          </p>
        </header>
        <hr className="border-1" />
        <div className="mt-10 flex flex-col gap-5 md:w-[500px]">
          <p className="lg:text-md text-left text-xs text-secondary-text">
            Kaggle’s community is made up of data scientists and machine
            learners from all over the world with a variety of skills and
            backgrounds. We strongly believe that our community and the future
            of the field are brighter when we embrace differences.
            <br />
            To help ensure that anyone in the world who loves working with data
            feels equally welcome to participate, we have created the community
            guidelines below. Our expectation is that all members of the Kaggle
            community will uphold these guidelines and contribute to their
            enforcement by reporting inappropriate content.
          </p>
          <div>
            <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
              General Guidelines
            </h2>
            <p className="lg:text-md text-left text-xs text-secondary-text">
              These guidelines apply to all user communication on kaggle.com,
              including Discussions, Notebooks, Datasets, etc. whether private
              or public.
              <br />
              Be patient. Be friendly.
              <br />
              Nuance is easily lost when communicating online especially when
              many people are not using their first language. Instead of making
              assumptions, stay calm and ask clarifying questions. If you feel
              you can’t be patient or friendly, take a step back and respond
              later.
            </p>
          </div>
          <div>
            <h2 className="mb-2 text-left text-xs font-semibold text-secondary-text lg:text-lg">
              Discuss and respect ideas. Don’t make it personal.
            </h2>
            <p className="lg:text-md text-left text-xs text-secondary-text">
              We’re all here to learn and share ideas. When you have critical
              feedback, focus on the ideas that others are sharing, not the
              person.
            </p>
          </div>
          <div>
            <h2 className="text-left text-xs font-bold text-secondary-text lg:text-xl">
              Self Promotion
            </h2>
            <p className="lg:text-md text-left text-xs text-secondary-text">
              If you want to promote your own work in the form of sharing links
              to your profile, notebooks or datasets, you may do so in either
              the Accomplishments forum or in the “sharing-projects” and
              “celebration” Discord channels. Sharing self-promoting posts in
              other forums or in comments may result in the removal of that
              content and a warning may be issued to the author.
              <br />
              Requesting upvotes or suggesting that other users should view or
              “check out” your work is considered upvote begging and is not
              allowed. Comments or posts of this nature may be removed and a
              warning may be issued to the author.
            </p>
          </div>
          <div>
            <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
              Forum and Discussion Posts
            </h2>
            <p className="lg:text-md text-left text-xs text-secondary-text">
              It is appropriate to share lists, articles, how-tos, industry
              advice, etc. in the forums, so long as your post:
              <ul className="list-disc">
                <li className="ml-5">Is of genuine value to other Kagglers</li>
                <li className="ml-5">
                  Is in alignment with the purpose of the forum or competition
                  you are posting in
                </li>
                <li className="ml-5">Is written and assembled by you</li>
                <li className="ml-5">Is not AI-generated</li>
                <li className="ml-5">Is not plagiarized from another source</li>
                <li className="ml-5">
                  Is not highly similar to another post on the forum
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
              Enforcement and Reporting
            </h2>
            <p className="lg:text-md text-left text-xs text-secondary-text">
              If you see something that you feel violates these guidelines
              please bring it to our attention using the flag option on messages
              and topics. We take your reports very seriously. If you have a
              concern that you think merits additional attention, you should
              report it to moderation@kaggle.com. All reports will be kept
              confidential.
              <br />
              The Kaggle team determines whether content is appropriate and we
              will make a decision internally. If we’ve found a violation of our
              community guidelines, repercussions may include (but are not
              limited to): Warning
              <br />A warning will be issued as a courtesy to any user who
              violates Kaggle's community guidelines and may be supplemented
              with the removal of content associated with the violation.
              Examples of behavior warranting a warning include but are not
              limited to upvote begging, spam posting, plagiarism and attempts
              to manipulate Kaggle's progression system.
              <br />
              Suspension
              <br />
              If more than one warning is issued, your account may be suspended
              (i.e., suspensions will only be issued to users who have
              previously received a warning). A user's access to Kaggle will be
              revoked for one month following their first suspension and for two
              months following their second suspension. Please note: we reserve
              the right to suspend your account without warning in more severe
              cases.
              <br />
              Ban
              <br />
              More than two account suspensions in the lifetime of an account
              may result in an immediate account ban. Additionally, immediate
              account bans may be issued to those who create duplicate accounts,
              post NSFW content, have usernames containing offensive language,
              post excessive spam or abuse kernel resources such as free
              storage. Bans are considered final.
            </p>
          </div>
          <div>
            <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
              Contact Us
            </h2>
            <p className="lg:text-md text-left text-xs text-secondary-text">
              Have a question about your account status or want to appeal a
              warning or suspension? Reach out to Kaggle through the{' '}
              <a href="/#" className="underline">
                contact page.
              </a>
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Guidelines;
