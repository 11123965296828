import React, { useEffect, useState } from 'react';
import FeedbackInput from '../common/FeedbackInput';
import DiscussionItem from './DiscussionItem';
import commentsIcon from '../../assets/images/messages.png';
import { Topic } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Comment } from '../../types';
import { useParams } from 'react-router-dom';
import CommentsContainer from './CommentsContainer';

const DiscussionDetails: React.FC = () => {
  const { discussionId } = useParams<{ discussionId: string }>();

  const [discussion, setDiscussion] = useState<Topic>();

  const fetchDiscussion = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/topics/${discussionId}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDiscussion(data);
      } else {
        console.log('Error fetching discussion');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDiscussion();
  }, []);

  useEffect(() => {
    if (discussion) setComments(discussion.comments);
  }, [discussion]);

  const [comments, setComments] = useState<Comment[]>([]);

  const user = useSelector((state: RootState) => state.user.user);

  const handleAddComment = async (content: string) => {
    if (content.trim()) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/comments/add-comment`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              topicId: discussion?.id,
              content,
            }),
          }
        );

        if (!response.ok) {
          console.log('Error adding comment');
        }
        const data = await response.json();

        const newComment = {
          ...data,
          _count: {
            comments: 0,
            commentVotes: 0,
          },
          user: {
            id: user.id,
            email: user.email,
            full_name: user.full_name,
            username: user.username,
            is_active: user.is_active,
          },
          comments: [],
        };
        // console.log(newComment);
        setComments([newComment, ...comments]);

        // console.log(data);
      } catch (error) {
        console.error(error);
      }
      // setReplyToCommentId(null);
    }
  };

  return (
    <div className="flex flex-col gap-4 text-start">
      {discussion && <DiscussionItem topic={discussion} />}

      <h2 className="text-2xl font-bold">{discussion?.name}</h2>
      <p className="text-gray-600">{discussion?.content}</p>
      <hr className="border-1 text-gray-600" />
      {/* Comment Section */}
      <div className="mb-4">
        <div className="mb-4 ml-2 flex items-center gap-2">
          <img src={commentsIcon} alt="Comments" className="w-[20px]" />
          <h3 className="text-xl font-semibold">
            {comments.length} Comment{comments.length > 1 ? 's' : ''}
          </h3>
        </div>
        {/* Add New Comment */}
        <div className="mt-2">
          <div className="flex items-start">
            {/* <Avatar
              image={discussion.authorPicture}
              size="large"
              shape="circle"
              className="mr-1 mt-4"
            /> */}
            <FeedbackInput
              onSubmit={handleAddComment}
              label="Post comment"
              placeholder="Comment here. Be patient, be friendly and focus on ideas. We’re all here to learn and improve!"
            />
          </div>
        </div>

        <CommentsContainer comments={comments} setComments={setComments} />
      </div>
    </div>
  );
};

export default DiscussionDetails;
