import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import uploadIcon from '../../assets/uploadIcon.png';
import StyledButton from '../../atoms/common/StyledButton';
import ImageUploadField from '../../components/ImageUploadField';
import FileUploadField from '../../components/FileUploadField';
import { CheckboxWithLabel } from '../createCompetition/CompetitionForm';
import { CompetitionFormField } from '../createCompetition/CompetitionForm';

const SubmissionForm = ({
  title,
  setTitle,
  description,
  setDescription,
  coverImage,
  setCoverImage,
  files,
  setFiles,
  is_private,
  setIsPrivate,
  handleSubmit,
}: {
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  coverImage: File | null;
  setCoverImage: (image: File) => void;
  files: File[];
  setFiles: (files: File[]) => void;
  is_private: boolean;
  setIsPrivate: (value: boolean) => void;
  handleSubmit: () => void;
}) => {
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  return (
    <div>
      <h1 className="text-start text-2xl font-bold sm:text-4xl">Submission</h1>
      <CompetitionFormField
        id="title"
        label="Title"
        value={title}
        onChange={(value) => setTitle(value)}
        placeholder="Enter a descriptive title"
        maxLength={50}
      />
      <CompetitionFormField
        id="description"
        label="Description"
        type="textArea"
        value={description}
        onChange={(value) => setDescription(value)}
        placeholder="Enter a description explaining what you did."
        maxLength={225}
      />
      <h1 className="my-2 text-start text-lg font-bold sm:text-xl">
        Project Cover
      </h1>
      <ImageUploadField
        accept={{ 'image/*': ['.jpeg', '.png'] }}
        image={coverImage}
        setImage={(image: File) => setCoverImage(image)} // Set cover image
        maxSize={5 * 1024 * 1024} // 5 MB
        icon={uploadIcon}
      />
      <h1 className="my-2 text-start text-lg font-bold sm:text-xl">
        Add Content
      </h1>
      <FileUploadField
        accept={{
          'image/*': ['.jpeg', '.png', '.gif'],
          'application/pdf': ['.pdf'],
        }}
        multiple
        files={files}
        setFiles={(files: File[]) => setFiles(files)} // Set files
        maxSize={5 * 1024 * 1024} // 5 MB per file
        icon={uploadIcon}
      />
      <div>
        <h1 className="my-2 block text-start text-sm font-medium text-black">
          Visibility <span className="text-red-600">*</span>
        </h1>
        <div className="flex flex-row justify-between">
          <CheckboxWithLabel
            checked={!is_private}
            onChange={() => {
              setIsPrivate(false);
            }}
            label="Public"
            description="Competition will be visible on Bidayya."
          />
          <CheckboxWithLabel
            checked={is_private}
            onChange={() => setIsPrivate(true)}
            label="Private"
            description="Competition will not be visible on Bidayya."
          />
        </div>
      </div>
      <div className="my-3 flex items-center justify-start gap-3">
        <StyledButton
          type="button"
          className="bg-blue-gradient-right text-xs font-bold"
          variant="primary"
          label="Submit"
          onClick={() => setButtonClicked(true)}
        />
        <StyledButton
          type="button"
          className="text-xs font-bold"
          variant="secondary"
          label="Cancel"
          onClick={() => window.history.back()}
        />
      </div>
      <Dialog
        header="Thank you"
        visible={buttonClicked}
        draggable={false}
        style={{
            width: 'fit-content',
            
        }}
        onHide={() => setButtonClicked(false)}
      >
        <div className="flex flex-col gap-3">
          <p>Are you sure you want to submit your design?</p>
          <div className="flex items-center justify-start gap-3">
            <StyledButton
              type="button"
              className="bg-blue-gradient-right w-fit text-xs font-bold"
              variant="primary"
              label="Submit"
              disabled={
                !title || !description || !coverImage || files.length === 0
              }
              onClick={handleSubmit}
            />
            <StyledButton
              type="button"
              className="w-fit text-xs font-bold"
              variant="secondary"
              label="Close"
              onClick={() => setButtonClicked(false)}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SubmissionForm;
