import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FeedbackMessage } from '../../types';
import { parseDate } from './CompetitionDetails';

const DashboardFeedback = ({ messages }: { messages: FeedbackMessage[] }) => {
  return (
    <div>
      {messages.map((message, index) => (
        <div key={index} className="my-2 border-b text-start">
          <div className="flex items-center justify-start gap-3">
            <div className="h-[50px] w-[50px] rounded-full bg-light-blue"></div>
            <div>
              <p>{message.contact}</p>
              <p>{parseDate(message.createdAt)}</p>
            </div>
          </div>
          <div
            className="prose ml-10 text-start"
            dangerouslySetInnerHTML={{ __html: message.content }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default DashboardFeedback;
